import {NBAStatus} from '@/types/NBAStatus'

export const isNew = (status: NBAStatus) => status === NBAStatus.new

export const isStatusEditable = (status: NBAStatus) => {
  return [NBAStatus.new, NBAStatus.draft].includes(status)
}

export const isStatusAllowedToSave = (status: NBAStatus) => {
  return [NBAStatus.draft].includes(status)
}

export const isPublishedOrCompleted = (status: NBAStatus) => {
  return [NBAStatus.published, NBAStatus.completed].includes(status)
}
