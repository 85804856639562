export enum NBAStatus {
  new = 'NEW',
  draft = 'DRAFT',
  published = 'PUBLISHED',
  completed = 'COMPLETED',
  paused = 'PAUSED',
  saved = 'SAVED',
  deleted = 'DELETED',
  disabled = 'DISABLED',
  in_progress = 'IN_PROGRESS',
  needs_review = 'NEEDS REVIEW',
}

export const NBAStatusList = [
  {id: 0, status: NBAStatus.new},
  {id: 1, status: NBAStatus.draft},
  {id: 2, status: NBAStatus.published},
  {id: 3, status: NBAStatus.completed},
  {id: 4, status: NBAStatus.paused},
  {id: 5, status: NBAStatus.saved},
  {id: 6, status: NBAStatus.deleted},
  {id: 7, status: NBAStatus.disabled},
  {id: 8, status: NBAStatus.needs_review},
]
