import {getCountries} from '@/helpers/envConfig'
import {isPublishedOrCompleted} from '@/helpers/statusHandler'
import {DaysSchema, RecurrenceDays} from '@/types/Day'
import {NBAType} from '@/types/NBAType'
import {Period} from '@/types/Period'
import {Recurrence} from '@/types/Recurrence'
import {TimeExecutionWindows} from '@/types/TimeExecution'
import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {NBAStatus} from '../../types/NBAStatus'
import {RewardType, RewardTypeSchema} from '@/types/rewardType'
import {ThresholdType, ThresholdTypeSchema} from '@/types/threshholdType'
import {LanguageType, TaskType} from '@/types/TaskSchema'

type NBAConfig = {
  id?: string
  name: string
  description: string
  type?: NBAType
  languages: Array<LanguageType>
  countryId: number | string
  countryCode: string
  statusId: number
  status: NBAStatus
  period?: Period
  displayPeriod?: Period
  tasks: Array<TaskType>
  /** radio */
  // awardAssignment: AwardType
  onAwardDate: string | undefined
  /** radio */
  fixReward: RewardType
  fixRewardPoints: string | number
  /** radio */
  treshhold: ThresholdType
  fixThreshold: string | number
  /** radio */
  recurrence?: Recurrence
  recurrenceDays: RecurrenceDays
  publishedDate?: string | null
  onTaskCompletion?: boolean
  copyNBAConfig?: string
}

export type NBAConfigEditableFields = Omit<NBAConfig, 'id' | 'status'>

export const newLanguage: LanguageType = {
  languageId: '',
  languageName: '',
  languageCode: '',
  displayText: '',
}

export const newTask: TaskType = {
  taskId: undefined,
  filePath: undefined,
  languages: [newLanguage],
  taskName: undefined,
  taskTypeId: undefined,
  taskTypeName: undefined,
}

export const NBAConfigInitialState: NBAConfig = {
  id: undefined,
  name: '',
  description: '',
  type: undefined,
  languages: [newLanguage],
  countryId: '',
  countryCode: '',
  statusId: 1,
  status: NBAStatus.draft,
  period: undefined,
  displayPeriod: undefined,
  tasks: [newTask],
  // awardAssignment: AwardTypeSchema.Values.TASK_COMPLETED,
  onTaskCompletion: true,
  onAwardDate: '',
  fixReward: RewardTypeSchema.Values.FIX_REWARD_POINTS,
  fixRewardPoints: '',
  treshhold: ThresholdTypeSchema.Values.FIX_THRESHHOLD,
  fixThreshold: '',
  recurrence: undefined,
  publishedDate: undefined,
  recurrenceDays: [DaysSchema.Values.MON],
  copyNBAConfig: '',
}

const nbaConfigSlice = createSlice({
  name: 'nbaConfig',
  initialState: NBAConfigInitialState,
  reducers: {
    setNBAMeta: (state, action: PayloadAction<Partial<NBAConfigEditableFields>>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setActiveNBA: (state, action: PayloadAction<Partial<NBAConfig>>) => {
      return {
        ...state,
        ...action.payload,
        id: action.payload.id,
      }
    },
    setCopyNBAConfig: (state, {payload}: PayloadAction<string>) => {
      return {
        ...state,
        copyNBAConfig: payload,
      }
    },
    setFirstLanguage: (state, {payload}: PayloadAction<LanguageType>) => {
      return {
        ...state,
        languages: [payload],
      }
    },
    setFirstTaskLanguage: (state, {payload}: PayloadAction<LanguageType>) => {
      return {
        ...state,
        tasks: state.tasks.map(task => ({
          ...task,
          languages: [payload],
        })),
      }
    },
    setCountry: (state, {payload}: PayloadAction<{countryId: number; countryCode: string}>) => {
      return {
        ...state,
        countryId: payload.countryId,
        countryCode: payload.countryCode,
        country: payload.countryCode,
      }
    },
    setNBAStatus: (state, {payload}: PayloadAction<NBAStatus>) => {
      if (isPublishedOrCompleted(state.status)) return

      return {
        ...state,
        status: payload,
      }
    },
    setPeriod: (state, {payload}: PayloadAction<Period | undefined>) => {
      if (isPublishedOrCompleted(state.status)) return

      const newPeriod = payload?.start ? payload : undefined
      return {
        ...state,
        period: newPeriod,
      }
    },
    setDisplayPeriod: (state, {payload}: PayloadAction<Period | undefined>) => {
      if (isPublishedOrCompleted(state.status)) return

      const newPeriod = payload?.start ? payload : undefined
      return {
        ...state,
        displayPeriod: newPeriod,
      }
    },
    setTasks: (state, {payload}: PayloadAction<Array<TaskType>>): void | NBAConfig => {
      if (isPublishedOrCompleted(state.status)) return

      return {
        ...state,
        tasks: payload,
      }
    },
    setDate: (state, {payload}: PayloadAction<string | undefined>) => {
      if (isPublishedOrCompleted(state.status)) return

      return {
        ...state,
        date: payload,
      }
    },
    setRecurrence: (state, {payload}: PayloadAction<Recurrence | undefined>) => {
      if (isPublishedOrCompleted(state.status)) return

      return {
        ...state,
        recurrence: payload,
      }
    },
    setTimeExecutionWindows: (state, {payload}: PayloadAction<TimeExecutionWindows | undefined>) => {
      if (isPublishedOrCompleted(state.status)) return

      return {
        ...state,
        timeExecutionWindows: payload,
      }
    },
    setNBAType: (state, {payload}: PayloadAction<NBAType | undefined>) => {
      if (isPublishedOrCompleted(state.status)) return

      return {
        ...state,
        type: payload,
      }
    },
    resetNBAConfig: (state, {payload}: PayloadAction<{forced: boolean} | undefined>) => {
      return {
        ...NBAConfigInitialState,
        country: payload?.forced ? getCountries()[0] : state.countryId || getCountries()[0],
      }
    },
  },
})

export const {
  setNBAMeta,
  setActiveNBA,
  setNBAStatus,
  setPeriod,
  setDisplayPeriod,
  setTasks,
  setDate,
  setRecurrence,
  setTimeExecutionWindows,
  setNBAType,
  resetNBAConfig,
  setFirstLanguage,
  setFirstTaskLanguage,
  setCountry,
  setCopyNBAConfig,
} = nbaConfigSlice.actions

export const nbaConfigSliceReducer = nbaConfigSlice.reducer
