import {GetNBAsResponse, NBAListItem} from '@/types/NBAs'
import {NBAStatus} from '@/types/NBAStatus'
import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

const initialState: GetNBAsResponse | undefined = {
  success: false,
  content: [],
  pagination: {
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
}

const nbaList = createSlice({
  name: 'nbaList',
  initialState,
  reducers: {
    setContent: (state, action: PayloadAction<GetNBAsResponse | undefined>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setUpdateContentByid: (state, {payload}: PayloadAction<NBAListItem>) => {
      const {id} = payload
      return {
        ...state,
        content: [
          ...state.content.map(item => {
            if (id === item.id) {
              return {...item, ...payload}
            } else {
              return item
            }
          }),
        ],
      }
    },
    setPublishedContentByid: (state, {payload}: PayloadAction<{id: string}>) => {
      const {id} = payload
      return {
        ...state,
        content: [
          ...state.content.map(item => {
            if (id === item.id) {
              return {...item, statusId: 2, status: NBAStatus.published}
            } else {
              return item
            }
          }),
        ],
      }
    },
    reset: () => initialState,
  },
})

export const {setContent, setUpdateContentByid, setPublishedContentByid, reset} = nbaList.actions

export const nbaListReducer = nbaList.reducer
