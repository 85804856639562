import {RootState} from '@/store'
import {CountriesType} from '@/types/CountriesResponse'
import {LanguagesType} from '@/types/LanguageResponse'
import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

interface country {
  id: number
  code: string
  name?: string
  active?: boolean
}

interface commonState {
  countries: CountriesType
  languages: LanguagesType
  languagesByCID: LanguagesType
  editMode: boolean
  country: country
  canCancelPage: boolean
  isDark: boolean
  refetchNbaList: boolean
}

const initialState: commonState = {
  countries: [],
  languages: [],
  languagesByCID: [],
  editMode: false,
  country: {id: 0, code: '', name: ''},
  canCancelPage: false,
  isDark: localStorage.getItem('isDark') === 'true' || false,
  refetchNbaList: false,
}

const common = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setCountries: (state, action: PayloadAction<CountriesType>) => {
      return {
        ...state,
        countries: action.payload,
      }
    },
    setLanguages: (state, action: PayloadAction<LanguagesType>) => {
      return {
        ...state,
        languages: action.payload,
      }
    },
    setLanguagesByCID: (state, action: PayloadAction<LanguagesType>) => {
      return {
        ...state,
        languagesByCID: action.payload,
      }
    },
    setEditMode: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        editMode: action.payload,
      }
    },
    setCountry: (state, action: PayloadAction<country>) => {
      // const countryObject =
      //   typeof action.payload === 'object' ? action.payload : state.countries.find(c => c.code === action.payload)
      return {
        ...state,
        country: action.payload,
      }
    },
    setCancelPage: state => {
      return {
        ...state,
        canCancelPage: true,
      }
    },
    setStayPage: state => {
      return {
        ...state,
        canCancelPage: false,
      }
    },
    setIsDark: (state, action: PayloadAction<boolean>) => {
      state.isDark = action.payload
      localStorage.setItem('isDark', state.isDark.toString())
    },
    refetchNBA: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        refetchNbaList: action.payload,
      }
    },
    resetCommon: () => initialState,
  },
})

export const {
  setCountries,
  setLanguages,
  resetCommon,
  setLanguagesByCID,
  setEditMode,
  setCountry,
  setCancelPage,
  setStayPage,
  setIsDark,
  refetchNBA,
} = common.actions

export const selectDarkMode = (state: RootState) => state.common.isDark
export const commonSliceReducer = common.reducer
