import {RootState} from '@/store'
import {NBAStatus} from '@/types/NBAStatus'
import {createListenerMiddleware, isAnyOf} from '@reduxjs/toolkit'
import {
  removeNodeDataRef,
  setNBAMeta,
  setNBAStatus,
  setNodeData,
  setPeriod,
  updateNodeParamsSelectors,
  updateNodeSendPushNotificationData,
  updateNodeSendSMSData,
  updateNodeSendWhatsAppData,
  updateNodeWaitData,
  updateReEntryDays,
} from '../slices'

export const nbaTemplateListener = createListenerMiddleware()

nbaTemplateListener.startListening({
  matcher: isAnyOf(
    removeNodeDataRef,
    setNBAMeta,
    setNodeData,
    setPeriod,
    updateNodeParamsSelectors,
    updateNodeSendPushNotificationData,
    updateNodeSendSMSData,
    updateNodeSendWhatsAppData,
    updateNodeWaitData,
    updateReEntryDays
  ),
  effect: async (_, listener) => {
    listener.getOriginalState()
    const {status} = (listener.getOriginalState() as RootState).journeyConfig

    const shouldChangeToDraft = [NBAStatus.saved].includes(status)

    if (shouldChangeToDraft) {
      listener.dispatch(setNBAStatus(NBAStatus.draft))
    }
  },
})
