import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {NBAExecutionStatus} from '@/types/NBAExecutionStatus'

const initialState: Partial<NBAExecutionStatus> = {}

const nbaExecutionStatusSlice = createSlice({
  name: 'nbaExecutionStatus',
  initialState: initialState,
  reducers: {
    setNBAExecutionStatus: (state, action: PayloadAction<NBAExecutionStatus>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    resetNBAExecutionStatus: () => {
      return initialState
    },
  },
})

export const {setNBAExecutionStatus, resetNBAExecutionStatus} = nbaExecutionStatusSlice.actions

export const nbaExecutionStatusSliceReducer = nbaExecutionStatusSlice.reducer
