import {isPublishedOrCompleted} from '@/helpers/statusHandler'
import {DaysSchema, RecurrenceDays} from '@/types/Day'
import {JourneyType, JourneyTypeSchema} from '@/types/JourneyType'
import {Period} from '@/types/Period'
import {Recurrence, RecurrenceSchema} from '@/types/Recurrence'
import {TimeExecutionWindows} from '@/types/TimeExecution'
import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {NBAStatus} from '../../types/NBAStatus'

type JourneyConfig = {
  id?: string
  name: string
  description: string
  country: number | string
  status: NBAStatus
  type?: JourneyType
  period?: Period
  recurrence?: Recurrence
  timeExecutionWindows?: TimeExecutionWindows
  recurrenceDays: RecurrenceDays
  publishedAt?: string | null
}

export type JourneyConfigEditableFields = Omit<JourneyConfig, 'id' | 'status'>

export const JourneyConfigInitialState: JourneyConfig = {
  id: undefined,
  name: '',
  description: '',
  country: 1,
  status: NBAStatus.new,
  type: undefined,
  period: undefined,
  recurrence: undefined,
  timeExecutionWindows: undefined,
  publishedAt: undefined,
  recurrenceDays: [DaysSchema.Values.MON],
}

const journeyConfigSlice = createSlice({
  name: 'journeyConfig',
  initialState: JourneyConfigInitialState,
  reducers: {
    setJourneyMeta: (state, action: PayloadAction<Partial<JourneyConfigEditableFields>>) => {
      const {recurrence, type, recurrenceDays} = action.payload
      const shouldResetRecurrenceDays =
        recurrence !== RecurrenceSchema.enum.WEEKLY || type === JourneyTypeSchema.enum.ONE_TIME || !recurrenceDays
      return {
        ...state,
        ...action.payload,
        recurrenceDays: shouldResetRecurrenceDays ? JourneyConfigInitialState.recurrenceDays : recurrenceDays,
      }
    },
    setJourneyStatus: (state, {payload}: PayloadAction<NBAStatus>) => {
      if (isPublishedOrCompleted(state.status)) return

      return {
        ...state,
        status: payload,
      }
    },
    setPeriod: (state, {payload}: PayloadAction<Period | undefined>) => {
      if (isPublishedOrCompleted(state.status)) return

      const newPeriod = payload?.start ? payload : undefined
      return {
        ...state,
        period: newPeriod,
      }
    },
    setRecurrence: (state, {payload}: PayloadAction<Recurrence | undefined>) => {
      if (isPublishedOrCompleted(state.status)) return

      return {
        ...state,
        recurrence: payload,
      }
    },
    setTimeExecutionWindows: (state, {payload}: PayloadAction<TimeExecutionWindows | undefined>) => {
      if (isPublishedOrCompleted(state.status)) return

      return {
        ...state,
        timeExecutionWindows: payload,
      }
    },
    setJourneyType: (state, {payload}: PayloadAction<JourneyType | undefined>) => {
      if (isPublishedOrCompleted(state.status)) return

      return {
        ...state,
        type: payload,
      }
    },
    resetJourneyConfig: (state, {payload}: PayloadAction<{forced: boolean} | undefined>) => {
      return {
        ...JourneyConfigInitialState,
        country: payload?.forced ? 0 : state.country || 0,
      }
    },
  },
})

export const {setJourneyMeta, setJourneyStatus, setJourneyType, resetJourneyConfig} = journeyConfigSlice.actions

export const journeyConfigSliceReducer = journeyConfigSlice.reducer
