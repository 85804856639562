import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

type DrawerPages = 'journeyConfigEditor'

interface JourneyDrawerState {
  isOpen: boolean
  title?: string
  canBeClosed?: boolean
  selectedPage?: DrawerPages
}

const initialState: JourneyDrawerState = {
  isOpen: false,
  canBeClosed: true,
  selectedPage: undefined,
  title: undefined,
}

const journeyDrawer = createSlice({
  name: 'journeyDrawer',
  initialState,
  reducers: {
    openJourneyDrawer: (
      state,
      action: PayloadAction<Pick<JourneyDrawerState, 'selectedPage' | 'title' | 'canBeClosed'>>
    ) => {
      return {
        ...state,
        isOpen: true,
        ...action.payload,
      }
    },
    closeJourneyDrawer: state => {
      if (!state.canBeClosed) return state
      return initialState
    },
    setCanBeClosed: (state, action: PayloadAction<Pick<JourneyDrawerState, 'canBeClosed'>>) => {
      return {
        ...state,
        canBeClosed: action.payload.canBeClosed,
      }
    },
    resetJourneyDrawer: () => initialState,
  },
})

export const {openJourneyDrawer, closeJourneyDrawer, resetJourneyDrawer} = journeyDrawer.actions

export const journeyDrawerSliceReducer = journeyDrawer.reducer
