import {journeyTemplateListener} from '@/store/middlewares/journeyTemplateListener'
import {nbaTemplateListener} from '@/store/middlewares/nbaTemplateListener'
import {configureStore} from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import {journeyStatusListener} from './middlewares/journeyStatusListener'
import {nbaStatusListener} from './middlewares/nbaStatusListener'
import {nodesDataWarningsListener} from './middlewares/nodesDataWarningsListener'
import {
  currentNodeSliceReducer,
  hasUnsavedChangesSliceReducer,
  journeyConfigSliceReducer,
  nbaConfigSliceReducer,
  nodesDataReducer,
  nodesRelationsSliceReducer,
} from './slices'
import {journeyDrawerSliceReducer} from './slices/journeyDrawer'
import {journeyExecutionStatusSliceReducer} from './slices/journeyExecutionStatusSlice'
import {nbaDrawerSliceReducer} from './slices/nbaDrawer'
import {nbaExecutionStatusSliceReducer} from './slices/nbaExecutionStatusSlice'
import {notificationsReducer} from './slices/notificationsSlice'
import {stepsSelectorReducer} from './slices/stepsSelectorSlice'
import {nbaManualTaskSliceReducer} from '@/store/slices/nbaManualTask'
import {commonSliceReducer} from '@/store/slices/commonSlice'
import {nbaListReducer} from '@/store/slices/nbaListSlice'

export const reducers = {
  journeyDrawer: journeyDrawerSliceReducer,
  currentNode: currentNodeSliceReducer,
  journeyConfig: journeyConfigSliceReducer,
  journeyExecutionStatus: journeyExecutionStatusSliceReducer,
  nbaDrawer: nbaDrawerSliceReducer,
  nbaConfig: nbaConfigSliceReducer,
  nbaExecutionStatus: nbaExecutionStatusSliceReducer,
  nbaManualTask: nbaManualTaskSliceReducer,
  nodesData: nodesDataReducer,
  nodesRelations: nodesRelationsSliceReducer,
  notifications: notificationsReducer,
  hasUnsavedChanges: hasUnsavedChangesSliceReducer,
  stepsSelector: stepsSelectorReducer,
  common: commonSliceReducer,
  nbaList: nbaListReducer,
}

export const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().prepend(
      journeyStatusListener.middleware,
      journeyTemplateListener.middleware,
      journeyStatusListener.middleware,
      nbaStatusListener.middleware,
      nbaTemplateListener.middleware,
      nodesDataWarningsListener.middleware
    ),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
