import {z} from 'zod'

export const ThresholdTypeSchema = z.enum(['FIX_THRESHHOLD', 'THRESHHOLD_FILE'])

export const ThresholdTypeValues = [
  ThresholdTypeSchema.Values.FIX_THRESHHOLD,
  ThresholdTypeSchema.Values.THRESHHOLD_FILE,
]

export type ThresholdType = z.infer<typeof ThresholdTypeSchema>
