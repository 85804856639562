import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import {z} from 'zod'

export const DrawerPagesTypeSchema = z.enum(['nbaConfigEditor', 'nbaPreview', 'nbaDetails'])
export type DrawerPages = z.infer<typeof DrawerPagesTypeSchema>

interface nbaDrawerState {
  isOpen: boolean
  title?: string
  canBeClosed?: boolean
  selectedPage?: DrawerPages
}

const initialState: nbaDrawerState = {
  isOpen: false,
  canBeClosed: true,
  selectedPage: undefined,
  title: undefined,
}

const nbaDrawer = createSlice({
  name: 'nbaDrawer',
  initialState,
  reducers: {
    openNBADrawer: (state, action: PayloadAction<Pick<nbaDrawerState, 'selectedPage' | 'title' | 'canBeClosed'>>) => {
      return {
        ...state,
        isOpen: true,
        ...action.payload,
      }
    },
    closeNBADrawer: state => {
      if (!state.canBeClosed) return state
      return initialState
    },
    setCanBeClosed: (state, action: PayloadAction<Pick<nbaDrawerState, 'canBeClosed'>>) => {
      return {
        ...state,
        canBeClosed: action.payload.canBeClosed,
      }
    },
    resetNBADrawer: () => initialState,
  },
})

export const {openNBADrawer, closeNBADrawer, resetNBADrawer} = nbaDrawer.actions

export const nbaDrawerSliceReducer = nbaDrawer.reducer
