import {newTask} from '@/store/slices/nbaConfigSlice'
import {TaskType} from '@/types/TaskSchema'
import {PayloadAction, createSlice} from '@reduxjs/toolkit'

type NBAManualTask = {
  isOpenModal: boolean
  activeManualTask: {
    idx: number
    task: TaskType
  }
}

export const NBAManualTaskInitialState: NBAManualTask = {
  isOpenModal: false,
  activeManualTask: {
    idx: -1,
    task: newTask,
  },
}

const nbaManualTaskSlice = createSlice({
  name: 'nbaManualTask',
  initialState: NBAManualTaskInitialState,
  reducers: {
    setModal: (state, {payload}: PayloadAction<boolean>) => {
      return {
        ...state,
        isOpenModal: payload,
      }
    },
    setActiveManualTask: (state: NBAManualTask, {payload}: PayloadAction<{idx: number; task: TaskType}>) => {
      return {
        ...state,
        activeManualTask: payload,
      }
    },
  },
})

export const {setModal, setActiveManualTask} = nbaManualTaskSlice.actions

export const nbaManualTaskSliceReducer = nbaManualTaskSlice.reducer
